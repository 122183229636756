import { SafeResourceUrl } from '@angular/platform-browser';

export class AccountData {
    forename: string;
    surname: string;
    email: string;
    accountGUID: string;
    verified: number;
    enabled: number;
    hasProfilePicture: boolean;
    imageUrl: string;
    password?: string;
    img?: SafeResourceUrl;
}