<!--<div id="pageContent">-->
<!--    <div id="header">-->
<!--        <div style="display: flex; align-items: center; margin-bottom: 20px;">-->
<!--            <img src="assets/svg-icons/Rosenbauer_Logo.svg" style="width: 160px"/>-->
<!--            <span translate id="closeDialog" style="margin-left: auto; font-size: 10pt; text-decoration: underline;" (click)="saveAllCookies()" *ngIf="currentStep === 1">cookie.save</span>-->
<!--        </div>-->
<!--    </div>-->
<!--    <mat-divider style="margin-bottom: 20px;"></mat-divider>-->
<!--    <div id="scrollContent" #scrollArea>-->
<!--        <div id="step1" [ngStyle]="{'display': currentStep === 1 ? 'flex' : 'none'}">-->
<!--            <h1 translate style="margin: 0px; margin-bottom: 5px; font-size: 18pt;">cookie.header</h1>-->
<!--            <span translate style="margin-bottom: 15px;">cookie.description</span>-->
<!--            <span translate style="margin-bottom: 20px;">cookie.usage</span>-->

<!--            <div class="type">-->
<!--                <div class="typeTitle" (click)="showEssentialDetails = !showEssentialDetails">-->
<!--                    <mat-icon *ngIf="!showEssentialDetails">expand_more</mat-icon>-->
<!--                    <mat-icon *ngIf="showEssentialDetails">expand_less</mat-icon>-->
<!--                    <span translate>cookie.essential.title</span>-->
<!--                </div>-->
<!--                <span *ngIf="showEssentialDetails" translate>cookie.essential.description</span>-->
<!--                <mat-divider style="margin-top: 10px; margin-bottom: 15px;"></mat-divider>-->
<!--            </div>-->

<!--            <div class="type">-->
<!--                <div class="typeTitle" (click)="showMarketingDetails = !showMarketingDetails">-->
<!--                    <mat-icon *ngIf="!showMarketingDetails">expand_more</mat-icon>-->
<!--                    <mat-icon *ngIf="showMarketingDetails">expand_less</mat-icon>-->
<!--                    <span translate>cookie.marketing.title</span>-->
<!--                </div>-->
<!--                <span *ngIf="showMarketingDetails" translate>cookie.marketing.description</span>-->
<!--                <mat-divider style="margin: 10px 0px;"></mat-divider>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div id="step2" [ngStyle]="{'display': currentStep === 2 ? 'flex' : 'none'}">-->
<!--            <form [formGroup]="formGroup" id="form" (submit)="saveCookieDecision()">-->
<!--                <h1 translate style="margin: 0px; margin-bottom: 5px; font-size: 18pt;">cookie.options.title</h1>-->
<!--                <span translate style="margin-bottom: 20px;">cookie.options.description</span>-->

<!--                <div class="outerWrapper">-->
<!--                    <div class="innerWrapper">-->
<!--                        <span class="cookieTitle" translate>cookie.options.essential.title</span>-->
<!--                        <mat-slide-toggle color="primary" style="margin-left: auto;" formControlName="essential" [disabled]="true" ></mat-slide-toggle>-->
<!--                    </div>-->
<!--                    <span class="cookieDescription" translate>cookie.options.essential.description</span>-->

<!--                </div>-->
<!--                <mat-divider style="margin: 20px 0px;"></mat-divider>-->
<!--                <div class="outerWrapper" style="margin-bottom: 50px;">-->
<!--                    <div class="innerWrapper">-->
<!--                        <span class="cookieTitle" translate>cookie.options.marketing.title</span>-->
<!--                        <mat-slide-toggle color="primary" style="margin-left: auto;" formControlName="marketing" ></mat-slide-toggle>-->
<!--                    </div>-->
<!--                    <span class="cookieDescription" translate>cookie.options.marketing.description</span>-->
<!--                </div>-->
<!--            </form>-->
<!--        </div>-->

<!--        <div style="display: flex; flex-direction: column;">-->
<!--            <div style="margin: 15px 0px;">-->
<!--                <span translate>cookie.agb_before</span>-->
<!--                <a translate [href]="privacyPolicyUrl" target="_blank">cookie.agb</a>-->
<!--                <span translate>cookie.agb_after</span>-->
<!--            </div>-->

<!--            <button mat-raised-button color="primary" id="saveButton" style="margin-bottom: 10px;" (click)="saveAllCookies()" *ngIf="currentStep === 1">{{'cookie.save' | translate }}</button>-->
<!--            <button mat-raised-button color="primary" id="saveButton" style="margin-bottom: 10px;" (click)="saveAllCookies()" *ngIf="currentStep === 2">{{'cookie.saveAll' | translate }}</button>-->
<!--            <button mat-raised-button id="saveButton" (click)="currentStep = 2" *ngIf="currentStep === 1">{{'cookie.optionButton' | translate }}</button>-->
<!--            <button mat-raised-button id="saveButton" (click)="saveCookieDecision()" *ngIf="currentStep === 2">{{'cookie.saveAndCloseButton' | translate }}</button>-->
<!--        </div>-->

<!--    </div>-->
<!--</div>-->
<h1 mat-dialog-title>
  <div style="display: flex; align-items: center; margin-bottom: 20px;">
    <img src="assets/svg-icons/Rosenbauer_Logo.svg" style="width: 160px"/>
    <span translate id="closeDialog" style="margin-left: auto; font-size: 10pt; text-decoration: underline;" (click)="saveAllCookies()" *ngIf="currentStep === 1">cookie.save</span>
  </div>
</h1>
<div mat-dialog-content>
  <div id="scrollContent" #scrollArea>
    <div id="step1" [ngStyle]="{'display': currentStep === 1 ? 'flex' : 'none'}">
      <h1 translate style="margin: 0px; margin-bottom: 5px; font-size: 18pt;">cookie.header</h1>
      <span translate style="margin-bottom: 15px;">cookie.description</span>
      <span translate style="margin-bottom: 20px;">cookie.usage</span>

      <div class="type">
        <div class="typeTitle" (click)="showEssentialDetails = !showEssentialDetails">
          <mat-icon *ngIf="!showEssentialDetails">expand_more</mat-icon>
          <mat-icon *ngIf="showEssentialDetails">expand_less</mat-icon>
          <span translate>cookie.essential.title</span>
        </div>
        <span *ngIf="showEssentialDetails" translate>cookie.essential.description</span>
        <mat-divider style="margin-top: 10px; margin-bottom: 15px;"></mat-divider>
      </div>

      <div class="type">
        <div class="typeTitle" (click)="showMarketingDetails = !showMarketingDetails">
          <mat-icon *ngIf="!showMarketingDetails">expand_more</mat-icon>
          <mat-icon *ngIf="showMarketingDetails">expand_less</mat-icon>
          <span translate>cookie.marketing.title</span>
        </div>
        <span *ngIf="showMarketingDetails" translate>cookie.marketing.description</span>
        <mat-divider style="margin: 10px 0px;"></mat-divider>
      </div>
    </div>
    <div id="step2" [ngStyle]="{'display': currentStep === 2 ? 'flex' : 'none'}">
      <form [formGroup]="formGroup" id="form" (submit)="saveCookieDecision()">
        <h1 translate style="margin: 0px; margin-bottom: 5px; font-size: 18pt;">cookie.options.title</h1>
        <span translate style="margin-bottom: 20px;">cookie.options.description</span>
        <div class="outerWrapper">
          <div class="innerWrapper">
            <span class="cookieTitle" translate>cookie.options.essential.title</span>
            <mat-slide-toggle color="primary" style="margin-left: auto;" formControlName="essential" [disabled]="true" ></mat-slide-toggle>
          </div>
          <span class="cookieDescription" translate>cookie.options.essential.description</span>
        </div>
        <mat-divider style="margin: 20px 0px;"></mat-divider>
        <div class="outerWrapper" style="margin-bottom: 50px;">
          <div class="innerWrapper">
            <span class="cookieTitle" translate>cookie.options.marketing.title</span>
            <mat-slide-toggle color="primary" style="margin-left: auto;" formControlName="marketing" ></mat-slide-toggle>
          </div>
          <span class="cookieDescription" translate>cookie.options.marketing.description</span>
        </div>
      </form>
    </div>
    <div style="display: flex; flex-direction: column;">
      <div style="margin: 15px 0px;">
        <span translate>cookie.agb_before</span>
        <a translate [href]="privacyPolicyUrl" target="_blank">cookie.agb</a>
        <span translate>cookie.agb_after</span>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="primary" id="saveButton" style="border-radius: 100px;" (click)="saveAllCookies()" *ngIf="currentStep === 1">{{'cookie.save' | translate }}</button>
  <button mat-flat-button color="primary" id="saveButton" style="border-radius: 100px;" (click)="saveAllCookies()" *ngIf="currentStep === 2">{{'cookie.saveAll' | translate }}</button>
  <button mat-flat-button id="saveButton" style="border-radius: 100px;" (click)="currentStep = 2" *ngIf="currentStep === 1">{{'cookie.optionButton' | translate }}</button>
  <button mat-flat-button id="saveButton" style="border-radius: 100px;" (click)="saveCookieDecision()" *ngIf="currentStep === 2">{{'cookie.saveAndCloseButton' | translate }}</button>
</div>
