import { Injectable } from '@angular/core';
import { ICookieNames } from '../Models/ICookieNames';

@Injectable({
    providedIn: 'root'
})

export class CookieService {
    get acceptedCookiesEssential():boolean{
      if(this.hasCookie(ICookieNames.ESSENTIAL)){
        return this.getCookieBooleanValue(ICookieNames.ESSENTIAL);
      }else{
        return false;
      }
    }

    get acceptedCookiesMarketing():boolean{
      if(this.hasCookie(ICookieNames.MARKETING)){
        return this.getCookieBooleanValue(ICookieNames.MARKETING);
      }else{
        return false;
      }
    }

    hasCookie(name: string){
      let cookieString = document.cookie;
      if(cookieString && cookieString.includes(name)){
        return true;
      } 
      return false;
    }

    getCookieBooleanValue(name: string){
        let cookieString = document.cookie;
        let cookieValue: boolean = null;
        if(cookieString){
          let splitted = cookieString.trim().split(";");
          let acceptedString = splitted.filter(val => val.includes(name))[0];
          if(acceptedString){
            cookieValue = acceptedString.split("=")[1] == 'true' ? true : false;
          }
        }
        return cookieValue;
    }
}