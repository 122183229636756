import { Injectable, Injector, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RefreshResponse } from '../Models/Account Management/RefreshResponse';
import { ValidateResponse } from '../Models/Account Management/ValidateResponse';
import { AccountData } from '../Models/Account Management/AccountData';
import config from "../../../config/config";
import { Application } from '../Models/Account Management/Application';
import { LoginErrorResponse } from '../Models/Account Management/LoginErrorResponse';
import { Term } from '../Models/Term';

@Injectable({
    providedIn: 'root'
})

export class AuthenticationService {

    accountGUID: string = "";
    authToken: string = null;
    refreshToken: string = "";
    profilePicture: SafeResourceUrl;
    accountData: AccountData = null;
    applications: Application[] = null;
    isLoggedInViaSocial = false;
    isLoadingData = false;
    validErrorCodes = ["errors.login.emailNotVerified", "errors.login.invalidCredentials", "errors.login.applicationNotAllowed", "errors.login.applicationNotAllowed","errors.login.accountBlocked", "errors.login.default"];

    constructor(private http: HttpClient, private router: Router, private sanitizer: DomSanitizer) {
    }

    async logout() {
        await this.logoutFromAccBack(this.authToken, true);
        this.removeAccountData();
        this.router.navigate(['login']);
    }

    async logoutFromAccBack(authToken: string, retry: boolean = true){
        try{
            let body = {authToken: authToken};
            let res = await this.http.put(config.accountMGMT.baseUrl + `/logout`, body).toPromise();
            if(!res) throw new Error("Response is null");
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.logoutFromAccBack(authToken, false);
            }
            console.log(`Error logoutFromAccBack: ` + err);
            throw err;
        }
    }

    removeAccountData() {
        localStorage.removeItem("accMGMT_authToken");
        localStorage.removeItem("accMGMT_refreshToken");
        localStorage.removeItem("accMGMT_accountGUID");
        this.accountData = null;
        this.applications = null;
        this.accountGUID = "";
        this.authToken = "";
        this.refreshToken = "";
    }

    handleLoginErrors(error: LoginErrorResponse){
        console.log(error);
        switch(error.result){
            case -2:
                return "errors.login.emailNotVerified"
            case -3:
                return "errors.login.invalidCredentials"
            case -4:
                return "errors.login.applicationNotAllowed"
            case -5:
                return "errors.login.accountBlocked"
            default:
                return "errors.login.default"
        }
    }

    async resetPW(email: string){
        try{
            let body = { email: email, applicationGUID: config.accountMGMT.applicationGUID };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put(config.accountMGMT.baseUrl + "/account/resetpassword", JSON.stringify(body), { headers: headers }).toPromise();
            if(!val) throw new Error("Response is null");
        }catch(err){
            console.error("Failed to Send E-Mail " + err.message);
            throw "errors.resetPWFailed";
        }
    }

    async createAccount(email: string, password: string, firstname: string, lastname: string, termGUIDS: string[]){
        try{
            let body = { email: email, password: password, forename: firstname, surname: lastname, termGUIDS: termGUIDS };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.post(config.accountMGMT.baseUrl + "/account/email", JSON.stringify(body), { headers: headers }).toPromise();
            if(!val) throw new Error("Response is null");
            this.accountData = new AccountData();
            this.accountData.email = email;
            this.accountData.password = password;
            this.accountData.forename = firstname;
            this.accountData.surname = lastname;
            localStorage.setItem("mobileMGMT_accountData", JSON.stringify(this.accountData));
        }catch(err){
            console.error("Failed to Create Account! " + err.message);
            if(err.message.includes("409")) throw "errors.accountExistsForEmail"
            throw "errors.createAccountFailed";
        }
    }

    async sendVerification(email: string){
        try{
            let body = { email: email};
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put(config.accountMGMT.baseUrl + "/account/email/sendverification", JSON.stringify(body), { headers: headers }).toPromise();
            if(!val) throw new Error("Response is null"); 
        }catch(err){
            console.error("Failed to Send Email! " + err.message);
            throw "errors.sendVerificationFailed";
        }
    }

    async loginViaEmail(email: string, password: string, device: string) {
        try {
            let body = { email: email, password: password, applicationGUID: config.accountMGMT.applicationGUID, device: device };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<{ authToken: string, refreshToken: string, accountGUID: string }>
                (config.accountMGMT.baseUrl + "/login/email", JSON.stringify(body), { headers: headers }).toPromise();

            this.accountGUID = val.accountGUID;
            this.authToken = val.authToken; 
            this.refreshToken = val.refreshToken
            localStorage.setItem("accMGMT_accountGUID", val.accountGUID);
            localStorage.setItem("accMGMT_authToken", val.authToken);
            localStorage.setItem("accMGMT_refreshToken", val.refreshToken);
            return val;
        } catch (err) {
            console.error("Failed to login via Email! " + err.message);
            if(err.error){
                throw this.handleLoginErrors(err.error)
            }else{
                throw "errors.login.default";
            }
        }
    }

    async loginViaGoogle(token: string, device: string){
        try {
            let body = { googleToken: token, applicationGUID: config.accountMGMT.applicationGUID, device: device };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<{ authToken: string, refreshToken: string, accountGUID: string }>
                (config.accountMGMT.baseUrl + "/login/google", JSON.stringify(body), { headers: headers }).toPromise();

            this.accountGUID = val.accountGUID;
            this.authToken = val.authToken;
            this.refreshToken = val.refreshToken
            localStorage.setItem("accMGMT_accountGUID", val.accountGUID);
            localStorage.setItem("accMGMT_authToken", val.authToken);
            localStorage.setItem("accMGMT_refreshToken", val.refreshToken);
            return val;
        } catch (err) {
            console.error("Failed to login via Email! " + err.message);
            if(err.error){
                throw this.handleLoginErrors(err.error)
            }else{
                throw "errors.login.default";
            }
        }
    }

    async loginViaFacebook(token: string, device: string){
        try {
            let body = { facebookToken: token, applicationGUID: config.accountMGMT.applicationGUID, device: device };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<{ authToken: string, refreshToken: string, accountGUID: string }>
                (config.accountMGMT.baseUrl + "/login/facebook", JSON.stringify(body), { headers: headers }).toPromise();

            this.accountGUID = val.accountGUID;
            this.authToken = val.authToken;
            this.refreshToken = val.refreshToken
            localStorage.setItem("accMGMT_accountGUID", val.accountGUID);
            localStorage.setItem("accMGMT_authToken", val.authToken);
            localStorage.setItem("accMGMT_refreshToken", val.refreshToken);
            return val;
        } catch (err) {
            console.error("Failed to login via Email! " + err.message);
            if(err.error){
                throw this.handleLoginErrors(err.error)
            }else{
                throw "errors.login.default";
            }
        }
    }

    async loginViaOneShot(token: string){
        try {
            let body = { oneShotToken: token, applicationGUID: config.accountMGMT.applicationGUID };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<{ authToken: string, refreshToken: string, accountGUID: string }>
                (config.accountMGMT.baseUrl + "/login/oneshot", JSON.stringify(body), { headers: headers }).toPromise();

            this.accountGUID = val.accountGUID;
            this.authToken = val.authToken;
            this.refreshToken = val.refreshToken
            localStorage.setItem("accMGMT_accountGUID", val.accountGUID);
            localStorage.setItem("accMGMT_authToken", val.authToken);
            localStorage.setItem("accMGMT_refreshToken", val.refreshToken);
            return val;
        } catch (err) {
            console.error("Failed to login via Email! " + err.message);
            if(err.error){
                throw this.handleLoginErrors(err.error)
            }else{
                throw "errors.login.default";
            }
        }
    }

    async loginViaRosenbauer(token: string, device: string){
        try{
            let body = { rosenbauerToken: token, applicationGUID: config.accountMGMT.applicationGUID, device: device };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<{ authToken: string, refreshToken: string, accountGUID: string }>
                (config.accountMGMT.baseUrl + "/login/rosenbauer", JSON.stringify(body), { headers: headers }).toPromise();

            this.accountGUID = val.accountGUID;
            this.authToken = val.authToken;
            this.refreshToken = val.refreshToken
            localStorage.setItem("accMGMT_accountGUID", val.accountGUID);
            localStorage.setItem("accMGMT_authToken", val.authToken);
            localStorage.setItem("accMGMT_refreshToken", val.refreshToken);
        }catch(err){
            console.error("Failed to login via Email! " + err.message);
            if(err.error){
                throw this.handleLoginErrors(err.error)
            }else{
                throw "errors.login.default";
            }
        }
    }

    async setPassword(token: string, password: string){
        try{
            let body = { password: password };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let res = await this.http.put(`${config.accountMGMT.baseUrl}/account/setpassword/${token}`, body, {headers: headers}).toPromise();
            if(!res) throw new Error("Response is null!");
        }catch(err){
            console.log(err);
            throw "errors.newPassword";
        }
    }

    async doRefreshToken(refreshToken: string) {
        try {
            let body = { refreshToken: refreshToken, applicationGUID: config.accountMGMT.applicationGUID };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<RefreshResponse>(config.accountMGMT.baseUrl + "/login/refresh", JSON.stringify(body), { headers: headers }).toPromise();
            if (val) {
                localStorage.setItem("accMGMT_authToken", val.authToken);
                localStorage.setItem("accMGMT_refreshToken", val.refreshToken);
                this.refreshToken = refreshToken;
                if(this.accountData){
                    this.accountData.forename = val.forename;
                    this.accountData.surname = val.surname;
                }
                return val.authToken;
            } else {
                throw new Error("Response is null");
            }
        } catch (err) {
            console.error("Error getting new Auth Token with Refresh Token!" + err.message);
        }
    }

    async validateAuthToken(authToken: string) {
        try {
            let body = { authToken: authToken };
            let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            let val = await this.http.put<ValidateResponse>(config.accountMGMT.baseUrl + "/login/validate", JSON.stringify(body), { headers: headers }).toPromise();
            if (val) {
                let regex = new RegExp(config.accountMGMT.applicationGUID, "i");
                if (val.applicationGUID && regex.test(val.applicationGUID)) {
                    return val;
                }else{
                    throw "Invalid Application GUID"
                }
            } else {
                throw new Error("Response is null");
            }
        } catch (err) {
            console.error("Error validating Authentication Token!" + err.message);
        }
    }

    async getProfilePictureBase64(authToken: string, retry: boolean = true) {
        try {
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let img = await this.http.get(config.accountMGMT.baseUrl + "/account/image/inline", { headers: headers, responseType: 'text' }).toPromise();
            if (img) {
                let imgSRC: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(img as string));
                this.profilePicture = imgSRC;
                return imgSRC;
            } else {
                throw new Error("Image is null!");
            }
        } catch (err) {
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getProfilePictureBase64(this.authToken, false);
            }
            console.log("Error Getting Profile Picture! " + err.message);
        }
    }

    async getProfilePictureForAccountGUID(accountGUID: string, retry: boolean = true): Promise<SafeResourceUrl> {
        try {
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${this.authToken}` });
            let img = await this.http.get(config.accountMGMT.baseUrl + `/account/${accountGUID}/image/inline`, { headers: headers, responseType: 'text' }).toPromise();
            if (img) {
                let imgSRC: string = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(img as string));
                return imgSRC;
            } else {
                throw new Error("Image is null!");
            }
        } catch (err) {
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getProfilePictureForAccountGUID(accountGUID, false);
            }
            console.log("Error Getting User Picture for: " + accountGUID + "! " + err.message);
        }
    }

    async setProfilePicture(image: Blob, authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}`, 'Content-Type': image.type});
            let res = await this.http.put(config.accountMGMT.baseUrl + `/account/image`, image, { headers: headers}).toPromise();
            if(res){
                return res
            }else{
                throw new Error("Res is null");
            }
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.setProfilePicture(image, this.authToken, false);
            }
            console.log("Error Setting Profile Picture for: " + authToken + "! " + err.message);
        }
    }

    async getProfileDataForAuthToken(authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let data = await this.http.get<AccountData>(config.accountMGMT.baseUrl + `/account/data`, { headers: headers}).toPromise();
            if(data){
                this.accountData = data;
                return data;
            }else{
                throw new Error("Data is null");
            }
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getProfileDataForAuthToken(this.authToken, false);
            }
            console.log("Error Getting User Data for: " + authToken + "! " + err.message);
        }
    }

    async setProfileData(forename: string, surname: string, authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}`});
            let body = {forename: forename, surname: surname};
            let res = await this.http.put(config.accountMGMT.baseUrl + `/account/data`, body, { headers: headers}).toPromise();
            if(res){
                return res
            }else{
                throw new Error("Res is null");
            }
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.setProfileData(forename, surname, this.authToken, false);
            }
            console.log("Error Setting Profile Data for: " + authToken + "! " + err.message);
        }
    }
    async getApplicationsForAccount(authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let data = await this.http.get<Application[]>(config.accountMGMT.baseUrl + `/account/applications`, { headers: headers}).toPromise();
            if(data){
                this.applications = data;
                return data;
            }else{
                throw new Error("Data is null");
            }
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getApplicationsForAccount(this.authToken, false);
            }
            console.log("Error Getting Applications for: " + authToken + "! " + err.message)
        }
    }

    async getApplicationWallpaperInline(application: Application, authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let data = await this.http.get(config.accountMGMT.baseUrl + `/application/wallpaperImage/${application.wallpaperImageGUID}/inline`, { headers: headers, responseType: 'text' }).toPromise();
            if(data){
                application.wallpaperImageSrc = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(data as string));
            }else{
                throw new Error("Data is null");
            }
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getApplicationWallpaperInline(application, this.authToken, false);
            }
            console.log("Error Getting ApplicationWallpaper for: " + authToken + "! " + err.message)
        }
    }

    async getApplicationIconInline(application: Application, authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let data = await this.http.get(config.accountMGMT.baseUrl + `/application/iconImage/${application.iconImageGUID}/inline`, { headers: headers, responseType: 'text' }).toPromise();
            if(data){
                application.iconImageSrc = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(data as string));
            }else{
                throw new Error("Data is null");
            }
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getApplicationIconInline(application, this.authToken, false);
            }
            console.log("Error Getting ApplicationImage for: " + authToken + "! " + err.message)
        }
    }

    async checkTokens() {
        let valid_res: ValidateResponse = null;
        this.accountGUID = localStorage.getItem("accMGMT_accountGUID");
        this.authToken = localStorage.getItem("accMGMT_authToken");
        if (this.authToken) {
            valid_res = await this.validateAuthToken(this.authToken);
        }
        if (!valid_res) {
            //Local Storage Auth Token is not valid
            let refreshToken = localStorage.getItem("accMGMT_refreshToken");
            if (refreshToken) {
                //Query new auth token with Refresh Token from Local Storage
                this.authToken = await this.doRefreshToken(refreshToken);
                if(this.authToken){
                    valid_res = await this.validateAuthToken(this.authToken);
                }
            }
            if (!valid_res) {
                //Couldn´t query proper Auth Token with Refresh Token from Local Storage --> Login via email
                console.log("User has to be logged in!");
                return false;
            } else {
                console.log("New Auth token queried with refresh Token is valid")
                if(!this.accountData) await this.getProfileDataForAuthToken(this.authToken);
                return true;
            }
        } else {
            console.log("Auth Token from Local Storage is valid");
            if(!this.accountData) await this.getProfileDataForAuthToken(this.authToken);
            return true;
        }
    }

    async logoutFromApplication(applicationGUID: string, authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res = await this.http.put(config.accountMGMT.baseUrl + `/account/logout/${applicationGUID}`, null, { headers: headers }).toPromise();
            if(!res) throw new Error("Response is null");
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.logoutFromApplication(applicationGUID, this.authToken, false);
            }
            console.log(`Error logging out of application: ` + err);
            throw err;
        }
    }

    async redirectToApplication(applicationGUID: string, authToken: string, target: string, source: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}`, 'Access-Control-Allow-Origin': "*",
            "Access-Control-Allow-Methods" : 'GET,POST,PATCH,DELETE,PUT,OPTIONS',
            "Access-Control-Allow-Headers" : 'Origin, Content-Type, X-Auth-Token, content-type' });
            let queryParams = target || source ? "?" : "";
            if(target) queryParams = `${queryParams}target=${target}`;
            if(source){
                if(target) queryParams = `${queryParams}&`; 
                queryParams = `${queryParams}source=${source}`; 
            }
            let res: {url: string} = await this.http.get(`${config.accountMGMT.baseUrl}/application/${applicationGUID}/redirect${queryParams}`, { headers: headers }).toPromise() as {url: string};
            return res.url;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.redirectToApplication(applicationGUID, this.authToken, target, source, false);
            }
            console.log(`Error redirecting to application: ` + err);
            throw err;
        }
    }

    async deactivateOwnAccount(authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res = await this.http.put(config.accountMGMT.baseUrl + `/account/deactivate`, null, { headers: headers }).toPromise();
            if(!res) throw new Error("Response is null");
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.deactivateOwnAccount(authToken, false);
            }
            console.log(`Error deactivating account: ` + err);
            throw err;
        }
    }

    async deleteOwnAccount(authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res = await this.http.put(config.accountMGMT.baseUrl + `/account/delete`, {}, {headers: headers }).toPromise();
            if(!res) throw new Error("Response is null");
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.deleteOwnAccount(authToken, false);
            }
            console.log(`Error deleting account: ` + err);
            throw err;
        }
    }

    async setNewPassword(authToken: string, oldPassword: string, newPassword: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let body = {oldPassword: oldPassword, newPassword: newPassword};
            let res = await this.http.put(config.accountMGMT.baseUrl + `/account/setpassword`, body, {headers: headers }).toPromise();
            if(!res) throw new Error("Response is null");
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.setNewPassword(authToken, oldPassword, newPassword, false);
            }
            console.log(`Error deleting account: ` + err);
            throw err;
        }
    }

    async getTerms(authToken: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res: Term[] = await this.http.get(config.accountMGMT.baseUrl + `/terms/`, {headers: headers }).toPromise() as Term[];
            if(!res) throw new Error("Response is null");
            return res;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getTerms(authToken, false);
            }
            console.log(`Error querying terms: ` + err);
            throw err;
        }
    }

    async getTermsForCulture(culture: string, authToken: string, retry: boolean = false){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res: Term[] = await this.http.get(config.accountMGMT.baseUrl + `/terms/culture/${culture ? culture : 'en'}`, {headers: headers }).toPromise() as Term[];
            if(!res) throw new Error("Response is null");
            return res;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getTermsForCulture(culture, authToken, false);
            }
            console.log(`Error querying terms: ` + err);
            throw err;
        }
    }

    async getTermsForApplication(applicationGUID: string, authToken: string, retry: boolean = false){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res: Term[] = await this.http.get(config.accountMGMT.baseUrl + `/terms/application/${applicationGUID}`, {headers: headers }).toPromise() as Term[];
            if(!res) throw new Error("Response is null");
            return res;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getTermsForApplication(applicationGUID, authToken, false);
            }
            console.log(`Error querying terms: ` + err);
            throw err;
        }
    }

    async getTermsForApplicationAndCulture(applicationGUID: string, culture: string, authToken: string, retry: boolean = false){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res: Term[] = await this.http.get(config.accountMGMT.baseUrl + `/terms/application/${applicationGUID}/culture/${culture ? culture : 'en'}`, {headers: headers }).toPromise() as Term[];
            if(!res) throw new Error("Response is null");
            return res;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.getTermsForApplicationAndCulture(applicationGUID, culture, authToken, false);
            }
            console.log(`Error querying terms: ` + err);
            throw err;
        }
    }

    async acceptTerm(authToken: string, termGUID: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res = await this.http.put(`${config.accountMGMT.baseUrl}/terms/${termGUID}/accept`, null, {headers: headers, responseType: 'text' }).toPromise();
            if(!res) throw new Error("Response is null");
            return res;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.acceptTerm(authToken, termGUID, false);
            }
            console.log(`Error accepting term: ${termGUID}: ` + err);
            throw err;
        }
    }

    async unacceptTerm(authToken: string, termGUID: string, retry: boolean = true){
        try{
            let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
            let res = await this.http.delete(`${config.accountMGMT.baseUrl}/terms/${termGUID}/accept`, {headers: headers, responseType: 'text' }).toPromise();
            if(!res) throw new Error("Response is null");
            return res;
        }catch(err){
            if(err.status == 401 && retry){
                await this.checkTokens();
                await this.unacceptTerm(authToken, termGUID, false);
            }
            console.log(`Error unaccepting term: ${termGUID}: ` + err);
            throw err;
        }
    }
}