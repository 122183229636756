import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from '../Models/MenuItem';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class HelperService {

    menuItems: MenuItem[] = [
        { id: 1, title: "menu-items.accountInfo", pageRoute: "home/account", iconName: "user" }
    ];
    stopTermCheck: Subject<boolean>;
    acceptedTerms: Subject<boolean>;

    constructor(private translate: TranslateService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
        this.stopTermCheck = new Subject<boolean>();
        this.acceptedTerms = new Subject<boolean>();
    }

    public initTranslations() {
        this.translate.addLangs(['en', 'de']);
        this.translate.setDefaultLang('de');

        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    }

    public addCustomIcons() {
        this.matIconRegistry.addSvgIcon('list', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/list.svg"));
        this.matIconRegistry.addSvgIcon('grid', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/grid.svg"));
        this.matIconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/edit.svg"));
        this.matIconRegistry.addSvgIcon('yes', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/yes.svg"));
        this.matIconRegistry.addSvgIcon('no', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/no.svg"));
        this.matIconRegistry.addSvgIcon('openInBrowser', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/openInBrowser.svg"));
        this.matIconRegistry.addSvgIcon('menuVertical', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/menu-vertical.svg"));
        this.matIconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/delete-bin.svg"));
        this.matIconRegistry.addSvgIcon('deactivate', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/deactivate.svg"));
        this.matIconRegistry.addSvgIcon('password', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/ic_password.svg"));
        this.matIconRegistry.addSvgIcon('termsAndConditions', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/termsAndConditions.svg"));        this.matIconRegistry.addSvgIcon('termsAndConditions', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/termsAndConditions.svg"));
        this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/download.svg"));
        this.matIconRegistry.addSvgIcon('logout', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/logout.svg"));
        this.matIconRegistry.addSvgIcon('openInNew', this.domSanitizer.bypassSecurityTrustResourceUrl("assets/svg-icons/open_in_new.svg"));
    }

    public iOS() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      }

}
