import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './MaterialModule';
import { HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainResolver } from './main.resolver';
import { CookieService } from './Services/CookieService';
import { CookieDialogComponent } from './cookie-dialog/cookie-dialog.component';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './Services/AuthenticationService';
import {MSAL_INSTANCE, MsalBroadcastService, MsalModule, MsalRedirectComponent, MsalService} from '@azure/msal-angular';
import {BrowserCacheLocation, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';

export class CustomTranslationLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any>{
    return from(import(`../assets/translations/${lang}-lang.json`))
  }
}

export async function openDialog (translateService: TranslateService,
                                  cookieService: CookieService,
                                  dialog: MatDialog,
                                  dialogRef: MatDialogRef<CookieDialogComponent>,
                                  auth: AuthenticationService){
  try {
    while(!cookieService.acceptedCookiesEssential) {
      console.log('Opening dialog.....');
      dialogRef = dialog.open(CookieDialogComponent, {panelClass: 'cookie-dialog', data: {translate: translateService}});
      await dialogRef.afterClosed().toPromise();
      auth.isLoadingData = true;
    }
  } catch(err) {
    console.log(err);
  }
}

export function cookieFactory(translate: TranslateService,
                              cookieService: CookieService,
                              dialog: MatDialog,
                              dialogRef: MatDialogRef<CookieDialogComponent>,
                              auth: AuthenticationService) {
translate.addLangs(['en', 'de']);
translate.setDefaultLang('en');
const browserLang = translate.getBrowserLang();
translate.use(browserLang.match(/en|de/) ? browserLang : 'en');

return () => openDialog(translate, cookieService, dialog, dialogRef, auth);
}

// export function MSALInstanceFactory(): IPublicClientApplication {
//   return new PublicClientApplication({
//     auth: {
//       clientId: '0cf6d6f9-8c4a-495e-b59c-ea285c27886e',
//       authority: 'https://login.microsoftonline.com/43b3bb10-a2ef-48e4-baaf-73112139511b',
//       redirectUri: window.location.origin
//     },
//     cache: {
//       cacheLocation: BrowserCacheLocation.LocalStorage
//     },
//     system: {
//       allowNativeBroker: false, // Disables native brokering support
//     }
//   });
// }


@NgModule({
    declarations: [
        AppComponent,
        CookieDialogComponent
    ],
    imports: [
        MaterialModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslationLoader,
            },
        }),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CommonModule
    ],
    providers: [
        MainResolver,
        {
            provide: APP_INITIALIZER,
            useFactory: cookieFactory,
            deps: [TranslateService, CookieService, MatDialog, MatDialogRef, AuthenticationService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
