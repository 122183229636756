import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from './Services/AuthenticationService';

@Injectable({
  providedIn: 'root'
})
export class MainResolver  {

  constructor(private auth: AuthenticationService){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.auth.isLoadingData = false;
    return of(true);
  }
}
