import local from "./local.json";
import testserver from "./testserver.json";
import azure from "./azure.json";
import live from "./live.json"
import { environment } from 'src/environments/environment';
import { Environment } from "src/app/Models/Environment";

let exportConfig = null;

switch(environment.environmentValue){
    case Environment.STAGING:
        exportConfig = azure;
        break;
    case Environment.RELEASE:
        exportConfig = live;
        break;
    default:
        exportConfig = azure;
        break;

}

export default exportConfig;
