import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ICookieNames } from '../Models/ICookieNames';
import { CookieService } from '../Services/CookieService';

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.css']
})
export class CookieDialogComponent implements OnInit, AfterViewInit {

  formGroup = new UntypedFormGroup({
    essential: new UntypedFormControl(true, [Validators.requiredTrue]),
    marketing: new UntypedFormControl(false),
  });
  currentStep: number = 1;
  showMarketingDetails: boolean = true;
  showEssentialDetails: boolean = true;
  privacyPolicyUrl: string = null;

  constructor(private dialogRef: MatDialogRef<CookieDialogComponent>, private cookieService: CookieService, private translate: TranslateService) { }

  ngOnInit() {
    if(this.cookieService.hasCookie(ICookieNames.MARKETING)){
      this.formGroup.get("marketing").setValue(this.cookieService.getCookieBooleanValue(ICookieNames.MARKETING));
    }
    this.translate.get("footer.privacyPolicyUrl").subscribe(val => {
      console.log(val);
      this.privacyPolicyUrl = val;
    });

    if(document.body) document.body.scrollTop = 0; // For Safari
    if(document.documentElement) document.documentElement.scrollTop = 0;
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(document.body) document.body.scrollTop = 0; // For Safari
      if(document.documentElement) document.documentElement.scrollTop = 0;
    })
  }

  saveCookieDecision(){
    let acceptedEssentialCookies = this.formGroup.get('essential').value;
    document.cookie = `${ICookieNames.ESSENTIAL}=${acceptedEssentialCookies}; max-age=63072000`;

    let acceptedMarketingCookies = this.formGroup.get('marketing').value;
    document.cookie = `${ICookieNames.MARKETING}=${acceptedMarketingCookies}; max-age=63072000`;  
    this.dialogRef.close(true);
  }

  saveAllCookies(){
    document.cookie = `${ICookieNames.ESSENTIAL}=${true}; max-age=63072000`;

    document.cookie = `${ICookieNames.MARKETING}=${true}; max-age=63072000`;  
    this.dialogRef.close(true);
  }

  closeDialog(){
    this.dialogRef.close(false);
  }

}
