import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './Services/AuthenticationService';
import { HelperService } from './Services/HelperService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(private helperService: HelperService, public auth: AuthenticationService){}

  ngOnInit(): void {
    this.helperService.initTranslations();
    this.helperService.addCustomIcons();  
  }
}
