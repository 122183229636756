import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import config from "../../config/config";
import { MainResolver } from './main.resolver';

//Routing "Configuration"
const routes: Routes = [
  {path: '', resolve: {MainResolver} , loadChildren: () =>  import('./main.module').then(m => m.MainModule), data: {key: "main", shouldReuse: false}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: config.routing.hash })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
